<template>
  <div class="ui-data-table-toolbar">
    <div class="slot-left" v-if="$slots['left']">
      <slot name="left"></slot>
    </div>

    <!-- Filtrador de columnas -->
    <ui-popover v-if="!hideColumnPicker">
      <template #trigger>
        <div class="ui-group">
          <ui-icon
            class="icon-columns ui-button ui-noselect"
            value="mdi:view-column"
            title="Mostrar columnas"
          ></ui-icon>
        </div>
      </template>

      <template #contents>
        <div class="toolbar-column-list">
          <label class="ui-label">Mostrar columnas</label>
          <ui-select-checklist
            v-model="columnNames"
            :options="columnOptions"
          ></ui-select-checklist>
        </div>
      </template>
    </ui-popover>

    <ui-pagination
      v-if="!hidePagination"
      :page="page"
      @update:page="$emit('update:page', $event)"
      :has-next="rows.length == limit"
      v-bind="$attrs"
    ></ui-pagination>

    <div class="slot-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import {
  UiIcon,
  UiPopover,
  UiField,
  UiPagination,
} from '@/modules/ui/components';
import UiSelectChecklist from '@/modules/ui/components/UiSelectChecklist/UiSelectChecklist.vue';

export default {
  name: 'ui-data-table-toolbar',
  components: { UiIcon, UiPopover, UiField, UiPagination, UiSelectChecklist },

  props: {
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },

    rows: {
      type: Array,
      required: false,
      default: () => [],
    },

    limit: {
      type: [String, Number],
      required: false,
      default: 30,
    },

    page: {
      type: [String, Number],
      required: false,
      default: 1,
    },

    visibleColumnNames: {
      type: Array,
      required: false,
      default: () => [],
    },

    hideColumnPicker: {
      type: Boolean,
      required: false,
      default: false,
    },

    hidePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    columnOptions() {
      return this.columns.map((column) => ({
        text: column.label,
        value: column.name,
      }));
    },

    columnNames: {
      get() {
        if (this.visibleColumnNames.length) {
          return this.visibleColumnNames;
        }

        return this.columns.map((col) => col.name);
      },

      set(value) {
        this.$emit('update:visibleColumnNames', value);
      },
    },
  },
};
</script>

<style lang="scss">
.ui-data-table-toolbar {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }

  .slot-right {
    margin-left: auto;
  }

  .toolbar-column-list {
    max-height: 300px;
    overflow-y: auto;
  }

  .icon-columns {
    color: rgba(0, 0, 0, 0.4);
  }
}
</style>